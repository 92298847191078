import "./App.css";
import React from "react";
import { useEffect, useState } from "react";
import images from "../config/images";
import Card from "../Card/Card";
//import Player from "../Player/Player";
import Map from "../Map/Map";
import skullImage from "../assets/img/skull.png";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

function App() {
  //console.log({ images });
  const [img, setImg] = useState([]);
  const [load, setLoad] = useState(false);
  images.tatto.reverse()

  useEffect(() => {
    //let arr=images[images.length-1]
    setImg(
      images.tatto.map((a) => <Card url={a} />)
    );


    
    const loadImage = (image) => {
      return new Promise((resolve, reject) => {
        const loadImg = new Image();
        loadImg.src = image;
        // wait 2 seconds to simulate loading time
        loadImg.onload = () => resolve(image);

        loadImg.onerror = (err) => reject(err);
      });
    };
    let im = images.all;
    im.unshift(skullImage);

    Promise.all(
      im.map((image) => {
        loadImage(image);
      })
    )
      .then(() =>
       setTimeout(() => {
        setLoad(true);
      }, 2500)
      )
      .catch((err) => console.log(err));
  }, []);

  var settings = {
    infinite: true,
    autoplay: true,
    accessibility: false,
    arrows: false,
    speed: 1000,
    autoplaySpeed:6000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <React.Fragment>
      <div
        style={{
          opacity: load ? 0 : 1,
          display: load ? "none" : "auto",
        }}
        className="loadpage"
      >
        <img alt="tatto photo" src={skullImage} />
      </div>
      <div
        style={{
          opacity: load ? 1 : 0,
        }}
      >
        <header className="header">
          <div className="icons-footer">
            <a
              href="https://wa.link/40u371"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img alt="whatsapp" src={images.social.whatsapp} />
            </a>
            <a
              href="https://www.facebook.com/BenditosTattoos"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img alt="facebook" src={images.social.facebook} />
            </a>
            <a
              href="https://www.instagram.com/benditostattoos/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img alt="instagram" src={images.social.instagram} />
            </a>
          </div>
        </header>
        <main>
          <div className="box-header">
            <div className="logo-d">
              <img alt="logo-d" src={images.background.logo} />
            </div>
            <div className="logo-t">
              <img alt="logo-t" src={images.background.logot} />
            </div>
            <div className="logo-m">
              <img alt="logo-m" src={images.background.logom} />
            </div>
          </div>
          <div
            className="banner"
            style={{
              backgroundImage: `url(${images.background.banner})`,
              filter: "hue-rotate(70deg)",
            }}
          ></div>

          <div>{/**Section with the last images on the instagram*/}</div>
          <div className="images parallax">
            {/**down here goes the images in a 3x3 grid */}

            <div>{img}</div>
          </div>
          <div
            className="banner"
            style={{ backgroundImage: `url(${images.background.banner})` }}
          ></div>

          <div>{/**Section with the images of the place*/}</div>

          <div className="description">
            <div>
              <h4>
                Si estas buscando tatuarte en un ambiente profesional y comodo,
                te esperamos!{" "}
              </h4>
              <br />
            </div>

            <div className="slider">
              <Slider {...settings} className="box-image">
               
                <img
                  alt="local"
                  src={images.artist.local1}
                />
            
                <img
                  alt="local"
                  src={images.artist.local2}
                />
              </Slider>
            </div>
            
          </div>
          <div
            className="banner"
            style={{
              backgroundImage: `url(${images.background.banner})`,
              transform: "scaleX(-1)",
            }}
          ></div>

          <div className="description">
            <div class="slider">
              <Slider {...settings}>
                <img
                  alt="artist-1"
                  src={images.artist.artist1}
                  className="desc-img"
                />
                <img
                  alt="artist-2"
                  src={images.artist.artist2}
                  className="desc-img"
                />
              </Slider>
            </div>

            <div>
              <h4>
                Somos un estudio de Tatto con más de 10 años de experiencia,
                siempre estamos buscando explorar nuevos estilos. Nuestra
                atención es totalmente personalizada
              </h4>
              <p>
                <br />
              </p>
            </div>
          </div>

          <div
            className="banner"
            style={{
              backgroundImage: `url(${images.background.banner})`,
              filter: "hue-rotate(200deg)",
            }}
          ></div>
        </main>
        <footer className="footer">
          <p>
            Estamos ubicados en Ramos Mejia, GBA. Nuestro local esta a pocas
            cuadras del centro.
            <br />
          </p>
          <div>
            <Map />
            <div>
              <div className="icons-footer">
                <a
                  rel="noopener noreferrer"
                  href="https://wa.link/40u371"
                  target="_blank"
                >
                  <img alt="whatsapp" src={images.social.whatsapp} />
                </a>
                <a
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/BenditosTattoos"
                  target="_blank"
                >
                  <img alt="facebook" src={images.social.facebook} />
                </a>
                <a
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/benditostattoos/"
                  target="_blank"
                >
                  <img alt="instagram" src={images.social.instagram} />
                </a>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </React.Fragment>
  );
}

export default App;

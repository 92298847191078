import React, { useState } from 'react';

const Card = ({ url }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <img
      alt={`image ${url}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={`img ${isHovered ? 'hover' : ''}`}
      src={url}
    />
  );
};

export default Card;
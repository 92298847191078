import whatsapp from "../assets/img/whatsapp.png";
import facebook from "../assets/img/facebook.png";
import instagram from "../assets/img/instagram.png";
import logo from "../assets/img/central.png";
import logot from "../assets/img/logomobile.png";
import logom from "../assets/img/mobile.png";

import banner from "../assets/img/sections/banner.png";
import lBanner from "../assets/img/sections/left-banner.png";
import rBanner from "../assets/img/sections/right-banner.png";
import skullBackground from "../assets/img/sections/nbackground.jpg";

import artist1 from "../assets/img/pic/artist-1.jpg";
import artist2 from "../assets/img/pic/artist-2.jpg";
import local1 from "../assets/img/pic/local-1.jpg";
import local2 from "../assets/img/pic/local-2.jpg";
import tatto1 from "../assets/img/pic/tatto-1.jpg";
import tatto2 from "../assets/img/pic/tatto-2.jpg";
import tatto3 from "../assets/img/pic/tatto-3.jpg";
import tatto4 from "../assets/img/pic/tatto-4.jpg";
import tatto5 from "../assets/img/pic/tatto-5.jpg";
import tatto6 from "../assets/img/pic/tatto-6.jpg";
import tatto7 from "../assets/img/pic/tatto-7.jpg";
import tatto8 from "../assets/img/pic/tatto-8.jpg";
import tatto9 from "../assets/img/pic/tatto-9.jpg";
import tatto10 from "../assets/img/pic/tatto-10.jpg";
import tatto11 from "../assets/img/pic/tatto-11.jpg";
import tatto12 from "../assets/img/pic/tatto-12.jpg";
import tatto13 from "../assets/img/pic/tatto-13.jpg";
import tatto14 from "../assets/img/pic/tatto-14.jpg";
import tatto15 from "../assets/img/pic/Tatto-15.jpg";
import tatto16 from "../assets/img/pic/Tatto-16.jpg";
import tatto17 from "../assets/img/pic/Tatto-17.jpg";
import tatto18 from "../assets/img/pic/Tatto-18.jpg";
import tatto19 from "../assets/img/pic/Tatto-19.jpg";
import tatto20 from "../assets/img/pic/Tatto-20.jpg";
import tatto21 from "../assets/img/pic/Tatto-21.jpg";
import tatto22 from "../assets/img/pic/Tatto-22.jpg";
import tatto23 from "../assets/img/pic/Tatto-23.jpg";
import tatto24 from "../assets/img/pic/Tatto-24.jpg";
import tatto25 from "../assets/img/pic/Tatto-25.jpg";
import tatto26 from "../assets/img/pic/Tatto-26.jpg";
import tatto27 from "../assets/img/pic/Tatto-27.jpg";
import tatto28 from "../assets/img/pic/Tatto-28.jpg";
import tatto29 from "../assets/img/pic/Tatto-29.jpg";
import tatto30 from "../assets/img/pic/Tatto-30.jpg";
import tatto31 from "../assets/img/pic/Tatto-31.jpg";
import tatto32 from "../assets/img/pic/Tatto-32.jpg";
import tatto33 from "../assets/img/pic/Tatto-33.jpg";
import tatto34 from "../assets/img/pic/Tatto-34.jpg";
import tatto35 from "../assets/img/pic/Tatto-35.jpg";
import tatto36 from "../assets/img/pic/Tatto-36.jpg";
import tatto37 from "../assets/img/pic/Tatto-37.jpg";
import tatto38 from "../assets/img/pic/Tatto-38.jpg";

export default {
  social: { whatsapp, facebook, instagram },
  background: { logo, logom, logot, banner, lBanner, rBanner, skullBackground },
  artist: { local1, local2, artist1, artist2 },
  tatto: [
    tatto1,
    tatto2,
    tatto3,
    tatto4,
    tatto5,
    tatto6,
    tatto7,
    tatto8,
    tatto9,
    tatto10,
    tatto11,
    tatto12,
    tatto13,
    tatto14,
    tatto15,
    tatto16,
    tatto17,
    tatto18,
    tatto19,
    tatto20,
    tatto21,
    tatto22,
    tatto23,
    tatto24,
    tatto25,
    tatto26,
    tatto27,
    tatto28,
    tatto29,
    tatto30,
    tatto31,
    tatto32,
    tatto33,
    tatto34,
    tatto35,
    tatto36,
    tatto37,
    tatto38
  ],
  all: [
    logo,
    logom,
    logot,
    banner,
    lBanner,
    rBanner,
    local1,
    local2,
    artist1,
    artist2,
    whatsapp,
    facebook,
    instagram,
    tatto1,
    tatto2,
    tatto3,
    tatto4,
    tatto5,
    tatto6,
    tatto7,
    tatto8,
    tatto9,
    tatto10,
    tatto11,
    tatto12,
    tatto13,
    tatto14,
  ],
};

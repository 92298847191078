import React from "react";
import "./Map.css";

//import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";

//-34.63891303762395, -58.561475137955085
//the coordenates is provisory
function WebMap() {
  return (
    <div className="map">
      <iframe 
        title="mapa"
        frameborder="0"
        allowfullscreen
        src="//umap.openstreetmap.fr/es/map/mapa-sin-titulo_814460?scaleControl=true&miniMap=false&scrollWheelZoom=false&zoomControl=true&allowEdit=false&moreControl=true&searchControl=null&tilelayersControl=null&embedControl=null&datalayersControl=true&onLoadPanel=undefined&captionBar=false"
      ></iframe>
    </div>
  );
}

export default WebMap;
